import React from 'react';
import styled from 'styled-components';

export function Charts() {
    
    return (
        <Wrapper>
           <div>charts</div>
           {/* <div>
                <div>week</div>
                <div>month</div>
                <div>year</div>
           </div>
           <div>
                
           </div> */}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    
`

